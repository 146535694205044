import React from 'react'
import Layout from '../templates/layout'
import Hero from '../components/Hero'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import '../styles/html-text.scss'

type Props = {
	data: {
		prismicStaticPages: {
			data: {
				meta_description: string
				meta_title: string
				name: string
				content: {
					html: string
				}
			}
			url: string
		}
	}
}
const PrismicStaticPagesUid = ({ data }: Props) => {
	const staticPage = data.prismicStaticPages.data
	return (
		<Layout border={true}>
			<SEO
				title={staticPage.meta_title}
				description={staticPage.meta_description}
				path={data.prismicStaticPages.url}
			/>
			<Hero
				header={staticPage.name}
				description=''
				links={[
					<Link to='/' className='mr-6 text-small text-grey' key='home-link'>
						Ana Sayfa
					</Link>,
				]}
			/>
			<div className='container mb-6'>
				<div className='lg:w-8/12 mx-auto font-merriweather leading-[34px]'>
					<div
						className='lg:mb-16 mb-8 html-text'
						dangerouslySetInnerHTML={{ __html: staticPage.content.html }}
					/>
				</div>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query StaticPage($uid: String) {
		prismicStaticPages(uid: { eq: $uid }) {
			data {
				meta_description
				meta_title
				name
				content {
					html
				}
			}
			url
		}
	}
`
export default PrismicStaticPagesUid
